<template>
  <div class="content-box">
    <p style="text-align: center"><strong>人脸信息处理协议</strong></p>
    <p><strong>本</strong><strong>协议</strong><strong>将帮助您了解以下内容：</strong></p>
    <p>一、我们提供的服务内容及个人信息处理规则</p>
    <p>二、我们如何保证您的个人信息安全</p>
    <p>三、您如何管理您的个人信息</p>
    <p>四、其他</p>
    <p>
      欢迎使用人脸核身服务！<br />根据相关法律法规的规定，您在使用联动优势电子商务有限公司（简称&ldquo;我们&rdquo;）提供的联动pay产品中的双录认证功能服务前需完成实人实名认证，《人脸核身服务个人信息处理授权协议》（以下简称&ldquo;本协议&rdquo;）是我们与用户（简称&ldquo;您&rdquo;）所订立的有效合约。
    </p>
    <p>
      <strong
        >我们在此善意的提醒您，在您使用我们的人脸核身服务（简称&ldquo;本服务&rdquo;）之前，请您务必审慎阅读、充分理解本协议各条款内容，本协议中与您的权益存在重大关系的条款，我们已采用加粗字体来特别提醒您，请您留意重点阅读。您应当仔细阅读、充分理解本协议的全部内容后再点击同意或勾选协议以使用本服务，一旦您主动点击同意按钮或勾选协议，将视为您同意接受本协议的全部内容。</strong
      ><br /><strong>如果您未满18周岁，请在法定监护人的陪同下阅读本协议并做出是否接受本协议内容的决定。</strong>
    </p>
    <h3><strong>一、我们提供的服务内容及个人信息处理规则</strong></h3>
    <p>
      1.您理解并同意，为了对您进行<strong>实人实名认证</strong>之目的，您同意我们将您的<strong>姓名</strong>、<strong>身份证号码</strong>或<strong>身份证照片</strong>传输给腾讯云计算（北京）有限责任公司及其合作服务商（简称&ldquo;腾讯云&rdquo;），<strong
        >并委托腾讯云通过摄像头、麦克风、网络、存储权限采集您的人脸信息和设备信息，对您的人脸信息进行活体检测并将您的上述身份信息向
        &ldquo;互联网+可信身份认证平台&rdquo;等核验机构传输</strong
      >，以实现身份信息的比对核验，从而验证您的身份。
    </p>
    <p>
      2.您理解并同意，为了对您进行<strong>实人实名认证和意愿确认</strong>之目的，您同意我们将您的<strong>姓名</strong>、<strong>身份证号码</strong>或<strong>身份证照片</strong>传输给腾讯云计算（北京）有限责任公司及其合作服务商（简称&ldquo;腾讯云&rdquo;），<strong
        >并委托腾讯云通过摄像头、麦克风、网络、存储权限采集您的人脸信息、朗读文字的音频和设备信息，对您的人脸信息进行活体检测并将您的上述身份信息向
        &ldquo;互联网+可信身份认证平台&rdquo;等核验机构传输</strong
      >，以实现身份信息的比对核验，从而验证您的身份。同时，我们还会对您<strong>朗读文字的音频进行核实</strong>，以核实您的认证意愿。<br /><strong
        >人脸信息、身份证属于敏感个人信息，且是我们为您提供本服务所必需。如您选择不提供或不同意我们处理以上敏感个人信息的，将导致您无法正常使用本服务，但不会影响您使用我们提供的其他身份验证服务（如有）</strong
      >。
    </p>
    <p>
      <strong>3.</strong
      ><strong
        >为了满足我们的实人实名认证服务需求以及提升本服务过程的安全性和准确性的必要，您同意我们对您的面部生物识别信息及相关视频图像、身份信息做必要的处理和保存，如您选择不提供或不同意我们处理以上敏感个人信息的，将导致您无法正常使用本服务</strong
      >。
    </p>
    <h3><strong>二、我们如何保证您的个人信息安全</strong></h3>
    <ol>
      <li>
        <strong>我们只会在符合法律法规规定的达成本协议所述目的所需最短期限内保存您在本服务中提交的信息和认证结果</strong
        >。为了保障您的个人信息安全，我们会在现有技术水平下采取合理必要的措施来保护您的个人信息，采取物理、技术和行政管理安全措施来降低丢失、误用、非授权访问、披露和更改的风险。如发生信息安全事件，我们有权根据法律规定及其应急管理制度处理您的个人信息，我们将及时告知您。
      </li>
      <li>
        除非您另行单独同意或者法律法规有明确要求，或者您因使用本服务及我们提供的产品或服务发生诉讼或仲裁等需要确认您身份的情形，我们不会向任何第三方提供您在实人实名认证过程中向我们提供的信息。
      </li>
    </ol>
    <p>
      <strong>3.</strong
      ><strong
        >本服务收集和处理的个人信息将存储于中华人民共和国境内，未经您单独同意，我们不会向境外传输您的个人信息</strong
      >。
    </p>
    <h3><strong>三、您如何管理您的个人信息</strong></h3>
    <p>
      如您希望<strong>查阅、复制、更改、删除、撤回同意</strong>您的个人信息，您可以通过<strong>APP意见反馈</strong
      ><strong>向我们提出需求</strong>，我们将尽快审核所涉问题并在15个工作日或法律法规规定的期限内予以反馈。
    </p>
    <h3><strong>四、其他</strong></h3>
    <p>
      1.我们努力在现有技术基础上保障人脸核身服务有效、稳定运行，但是由于技术的限制、采集人脸信息过程中容易受到环境光线、表情、妆容等不特定因素的影响，且我们能用于认证的信息范围相对有限，技术水平和认证手段也在不断升级改进之中，<strong>我们暂时无法保证每次人脸核身都能成功</strong>。如果您发现实人实名认证不成功，您可以重新发起认证流程或者通过客服电话：4001125882联系我们，以便重新进行认证并提供认证服务水平。您也可以选择我们提供的其他认证方式（如有）完成身份真实性核验，但根据服务性质及风险控制等原因必须以人脸验证方式进行认证的除外。<strong>为了保护您及他人的合法权益，我们有权在认为可能存在风险时暂停向您提供人脸核身相关服务</strong>。
    </p>
    <p>
      2.为给您提供更好的服务或因国家法律法规、政策调整、技术条件、产品功能等变化需要，我们会适时对本协议进行更新并根据相关法律法规进行公示。<strong>如您对更新后的内容有异议的，请立即停止使用本服务。若您知晓并继续使用本服务，即视为您认可并接受更新后的协议内容</strong>。
    </p>
    <p>
      <strong>3.</strong
      ><strong
        >您已阅读并理解本授权书所有内容（特别是加粗字体内容）的意义以及由此产生的法律效力，自愿作出上述授权，本授权书是您真实的意思表示，您同意承担相关责任及后果</strong
      >。
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.content-box {
  // width: 100%;
  margin: 30px;
  padding-bottom: 30px;
  word-break: break-all;
  table {
    border-collapse: collapse;
    width: 100%;
  }
}
</style>
